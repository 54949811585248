import { Accordion, AccordionContent, AccordionPanel, AccordionTitle } from 'flowbite-react';
import { installationProcessDetails } from '../../constants/constants';
import { FaTools } from 'react-icons/fa';

const InstallationExpectations = () => {
    return (
        <Accordion collapseAll className="border-white">
            <AccordionPanel>
                <AccordionTitle className="text-ms3-blue border-0 border-white bg-transparent hover:border-0 hover:bg-transparent focus:ring-0">
                    <div className="flex flex-row items-center gap-3">
                        <FaTools className="text-lg" />
                        What to expect from your install?
                    </div>
                </AccordionTitle>
                <AccordionContent>
                    <div className="shadow-ms3-blue-xl border-0 rounded-lg p-5 mb-5 text-justify leading-relaxed">
                        <div className="font-bold mb-2 text-ms3-blue">Installation Process</div>
                        <div>{installationProcessDetails}</div>
                    </div>
                </AccordionContent>
            </AccordionPanel>
        </Accordion>
    )
}

export default InstallationExpectations;