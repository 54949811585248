const Ms3Button = ({ id, text, disabled, onClick, size }) => {
    return (
        <button
            className={`px-8 py-3 rounded-full font-bold transition-colors duration-300 focus:ring-0 bg-ms3-ultrapink text-white hover:bg-ms3-pink text-md ${size ? 'md:text-' + size : 'md:text-xl'}`}
            type="submit"
            id={id}
            disabled={disabled}
            onClick={onClick}>
            {text}
        </button>
    )
}

export default Ms3Button;