import React, { useEffect, useState } from 'react';
import { Card } from 'flowbite-react';
import Input from '../common/input/input';
import { validateField, validatePostcode } from '../../util/validation/validation';
import Ms3Button from '../common/button/button';
import Ms3Dropdown from '../common/dropdown/dropdown';
import Loader from '../common/loader/loader';
import { providers, unknownProviders } from '../../constants/constants';
import { useLocation } from 'react-router-dom';
import { useApiClient } from '../../api/useApiClient';
import { useDebounce } from 'use-debounce';
import { trackEvent } from '../../gtm/gtm';

const WEBSITE_BASE_URL = process.env.REACT_APP_WEBSITE_BASE_URL;

const AddressChecker = ({ address, setAddress, updateAddress, onSubmit }) => {

    const location = useLocation();
    const { callApi, loading } = useApiClient();

    const [errors, setErrors] = useState({
        postcode: false,
        fullAddress: false,
        provider: false,
        agentReference: false
    });

    const [availability, setAvailability] = useState(true);
    const [showAgentReference, setShowAgentReference] = useState(false);
    const [postcode, setPostcode] = useState(address.postcode);
    const [debouncedPostcode] = useDebounce(postcode, 1000);
    const [noResults, setNoResults] = useState(false);

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const code = queryParams.get('postcode');
        const agentReferenceParam = queryParams.get('agentReference');
        const reference = agentReferenceParam ? agentReferenceParam : address.agentReference;

        if (code) {
            setAddress({
                ...address,
                agentReference: reference,
                postcode: code
            });

            setPostcode(code);
        } else {
            setAddress({
                ...address,
                agentReference: reference
            });
        }

        setShowAgentReference(false);
        for (const [key] of queryParams.entries()) {
            if (key === "agentReference") {
                setShowAgentReference(true);
            }
        }

        if (!address.fullAddress && debouncedPostcode && validatePostcode(debouncedPostcode)) {
            handleSearchAddresses(debouncedPostcode, reference);
        }
    }, [location.search, setAddress, debouncedPostcode]);

    const handlePostCodeChange = (e) => {
        const { value } = e.target;
        setPostcode(value);
        setNoResults(false);

        updateAddress((prevDetails) => ({
            ...prevDetails,
            uprn: "",
            fullAddress: "",
            buildingNumber: "",
            street: "",
            searchList: [],
            postcode: value.toUpperCase(),
        }));

        setErrors({
            ...errors,
            postcode: false
        })
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            if (validateFields("postcode", postcode)) {
                handleSearchAddresses(postcode, address.agentReference);
            }
        }
    };

    const setProvider = (provider) => {
        trackEvent('provider_selected', 'address');

        setAddress((prevDetails) => ({
            ...prevDetails,
            provider: provider,
        }));

        validateFields("provider", provider);
    };

    const setAgentReference = (e) => {
        const { value } = e.target;

        setAddress((prevDetails) => ({
            ...prevDetails,
            agentReference: value
        }));

        if (value) {
            validateFields("agentReference", value);
        }
    }

    const validateFields = (name, value) => {
        const valid = validateField(name, value);

        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: !valid,
        }));

        return valid;
    }

    const handleSearchAddresses = async (postcode, agentReference) => {
        const result = await callApi('searchAddresses', null, postcode);
        updateAddress({
            ...address,
            postcode: postcode,
            agentReference: agentReference,
            searchList: result
        });

        setNoResults(!errors.postcode && (!result || result.length === 0));
    };

    const handleSelect = async (option) => {
        trackEvent('address_selected', 'address');

        var element = address.searchList.filter((el) => el.fullAddress === option)[0];

        const response = await callApi('getAddressAvailability', null, element.uprn);
        setAvailability(response && response.hasFTTPAvailability && !response.isInLoc);

        updateAddress({
            ...address,
            uprn: element.uprn,
            fullAddress: element.fullAddress,
            buildingNumber: element.buildingNumber,
            street: element.street,
            isBusiness: response && response.propertyClassification === 'Business'
        });

        validateFields("fullAddress", option);
    }

    const handleSubmit = (e) => {
        trackEvent('find_deals_button_pressed', 'address');

        e.preventDefault();

        var hasErrors = false;
        const requiredFields = ['postcode', 'provider', 'fullAddress'];
        requiredFields.forEach(field => {
            if (!validateFields(field, address[field])) {
                hasErrors = true;
            }
        });

        if (!hasErrors) {
            onSubmit();
        }
    };

    const buildRegisterInterestUrl = () => {
        return WEBSITE_BASE_URL +
            "/register-interest" +
            "?fullAddress=" + address.fullAddress +
            "&buildingNumber=" + address.buildingNumber +
            "&street=" + address.street +
            "&postcode=" + address.postcode
    }

    const buildNotABusinessUrl = () => {
        return WEBSITE_BASE_URL +
            "/not-a-business" +
            "?fullAddress=" + address.fullAddress +
            "&buildingNumber=" + address.buildingNumber +
            "&street=" + address.street +
            "&postcode=" + address.postcode
    }

    return (
        <>
            {loading && <Loader />}

            <div className="md:my-20 my-5 flex flex-col items-center md:mx-0 mx-3">
                <Card className="md:max-w-3xl max-w-md md:w-3/5 md:p-10 p-3 shadow-ms3-blue-xl border-0">
                    <form className="md:flex flex-col">

                        <Input
                            name="postcode"
                            label="What is your postcode?"
                            placeholder="Postcode"
                            value={postcode.toUpperCase()}
                            onChange={handlePostCodeChange}
                            onKeyDown={handleKeyDown}
                            invalid={errors.postcode}
                        />

                        {!loading && noResults && (
                            <div className='mb-5 font-bold text-ms3-red'>
                                No addresses found. Please enter a valid postcode.
                            </div>
                        )}

                        {!loading && address.searchList && address.searchList.length > 0 && (
                            <Ms3Dropdown
                                label={address.fullAddress ? 'Selected address' : 'Select an address'}
                                options={address.searchList.map((element) => element.fullAddress)}
                                value={address.fullAddress}
                                selectOption={handleSelect}
                                placeholder="Address"
                                invalid={errors.fullAddress}
                            />
                        )}

                        <Ms3Dropdown
                            label="Who is your current provider?"
                            options={providers.sort()}
                            additionalOptions={unknownProviders}
                            value={address.provider}
                            selectOption={setProvider}
                            placeholder="Provider"
                            invalid={errors.provider}
                        />

                        {(showAgentReference || address.agentReference) && (
                            <Input
                                name="agentReference"
                                label="Agent Reference"
                                placeholder="Reference"
                                value={address.agentReference}
                                onChange={setAgentReference}
                                invalid={errors.agentReference}
                            />
                        )}
                    </form>

                    {address.isBusiness && (
                        <div className='flex md:flex-row flex-col gap-3 items-center justify-between mt-2'>
                            <div className="text-lg text-ms3-blue font-bold">Business Address Identified</div>
                            <a className="text-md font-bold text-gray-900 hover:bg-gray-100 transition-colors duration-300 rounded-full px-4 py-2" href={buildNotABusinessUrl()}>
                                Not a Business
                            </a>
                        </div>
                    )}

                    <div className="flex md:flex-row flex-col gap-3 items-center justify-evenly mt-2">
                        {availability && !address.isBusiness && (
                            <Ms3Button id="findDealsButton" text="Find Deals" onClick={handleSubmit} />
                        )}

                        {availability && address.isBusiness && (
                            <Ms3Button id="findBusinessDealsButton" text="Find FTTP Business Deals" onClick={handleSubmit} size='lg' />
                        )}

                        {!availability && (
                            <a href={buildRegisterInterestUrl()}>
                                <Ms3Button id="registerInterestButton" text="Find Deals" />
                            </a>
                        )}
                    </div>
                </Card>
            </div>
        </>
    )
}

export default AddressChecker;
